import { useEventBus } from '@vueuse/core'
import { MJPSection } from '@/components/magicjohnsonpark/types'

const sectionChangedBus = useEventBus('section-changed')

export default function useSkipToTour(currentSection) {
  console.log('skip to tour')
  sectionChangedBus.emit({
    section: MJPSection.InvitationToExplore,
    pageNumber: 0,
    forward: true,
    lastSection: currentSection,
  })
}
