<template>
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M45 0C22.3853 0 4 18.3853 4 41C4 63.6147 22.3853 82 45 82C67.6147 82 86 63.6147 86 41C86 18.3853 67.6147 0 45 0ZM45 78.5474C24.2842 78.5474 7.45263 61.7158 7.45263 41C7.45263 20.2842 24.2842 3.45263 45 3.45263C65.7158 3.45263 82.5474 20.2842 82.5474 41C82.5474 61.7158 65.7158 78.5474 45 78.5474Z"
        fill="white"
      />
      <path
        d="M62.0907 38.2388L38.2675 23.3925C36.1097 22.0114 33.2612 23.5651 33.2612 26.1546V55.9335C33.2612 58.523 36.1097 60.0767 38.2675 58.6956L62.0907 43.8493C64.1623 42.4683 64.1623 39.5335 62.0907 38.2388Z"
        fill="white"
      />
    </g>
  </svg>
</template>
