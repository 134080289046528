import { MJPSection } from '@/components/magicjohnsonpark/types'

export default function useUserIsOnTour(section) {
  switch (section) {
    case MJPSection.WaterTreatmentFacility:
    case MJPSection.Wetlands:
    case MJPSection.Lakes:
    case MJPSection.StreamBridgeAndTrail:
    case MJPSection.SplashPad:
    case MJPSection.CommunityCenter:
      return true

    default:
      return false
  }
}
