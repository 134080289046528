<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="42"
      height="42"
      rx="21"
      stroke="#F1EEE9"
      stroke-width="2"
    />
    <path
      d="M12 20L22.3445 27L32 20"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
