<template>
  <svg
    width="339"
    height="78"
    viewBox="0 0 339 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M176.316 78C125.93 77.9687 90.341 77.0724 55.6086 72.2342C39.392 69.978 23.6645 67.0386 11.288 60.8466C1.76108 56.0835 1.08845 49.8351 9.53919 44.5581C10.9089 43.7058 13.6851 43.4488 15.813 42.9161C15.2872 43.9753 15.2872 45.235 14.1376 46.0622C7.14217 51.0823 7.77811 55.156 16.877 59.2923C27.5291 64.1244 40.5171 66.6062 53.9209 68.5365C83.5902 72.8045 114.03 74.0078 144.678 74.2397C186.344 74.553 227.852 73.8887 268.504 68.5052C283.363 66.5372 297.696 63.8486 310.415 59.3049C336.354 50.0482 338.996 36.0096 317.031 24.2836C304.863 17.7908 289.747 13.7359 273.42 10.8593C233.661 3.83996 192.545 2.3797 150.939 3.68955C113.957 4.85525 79.0897 9.81263 47.4636 19.9969C30.9168 25.324 16.4735 31.8482 4.87966 39.9768C3.81568 40.7288 1.65101 41.0673 0 41.6C0.4525 40.6035 0.366892 39.419 1.43088 38.6481C20.5826 24.7098 46.8521 15.4782 77.1696 9.04802C106.166 2.89988 136.569 0.248846 167.829 0.029493C207.404 -0.252532 246.441 1.39575 283.705 8.99162C297.831 11.8745 310.941 15.6662 321.569 21.3506C334.667 28.3573 341.833 36.4985 337.944 46.4007C335.486 52.6491 327.977 57.2931 318.328 61.066C299.335 68.4864 277.052 71.7641 254.134 74.0955C223.621 77.2041 192.704 78.0251 176.304 77.9937L176.316 78Z"
      fill="#F6BB45"
    />
  </svg>
</template>
