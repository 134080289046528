<template>
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8204 0.295915L5.76964 6.61052H2.89667C1.76932 6.61052 0.713176 7.28815 0.413779 8.4224C0.144388 9.44298 0.000488281 10.5178 0.000488281 11.6278C0.000488281 12.7377 0.144388 13.8126 0.413779 14.8331C0.713177 15.9674 1.76932 16.645 2.89667 16.645H5.76964L11.8204 22.9596C12.4261 23.5918 13.4618 23.1441 13.4618 22.2501V1.00546C13.4618 0.111485 12.4261 -0.336223 11.8204 0.295915Z"
      fill="white"
    />
  </svg>
</template>
