<template>
  <div
    class="section-page fixed top-0 left-0 w-full h-full pointer-events-none"
  >
    <slot></slot>
  </div>
</template>

<style scoped>
.section-page {
  transition: opacity 1s;
}
</style>
