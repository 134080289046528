import { useLanguageStore } from '@/store/language'
import { getTranslation } from '@/i18n/getTranslation'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

export default function useLanguage(key: string) {
  const languageStore = useLanguageStore()
  const { language } = storeToRefs(languageStore)
  const translationRef = ref(
    getTranslation(language.value)[key as keyof typeof language.value] ??
      'Text not available'
  )

  watch([language], () => {
    translationRef.value =
      getTranslation(language.value)[
        key as keyof typeof translationRef.value
      ] ?? 'Text not available'
  })

  return translationRef
}
