/**
 * This data is exported (though not in a fully automated way) from
 * the World Remedies model described here: https://www.notion.so/sphericalstudio/4347cb4965244b039ab930a6e67b603e?v=508949fa4b1d46b8a98a02163c94afd6
 *
 * CAVEAT: The media-based icons were not exported from the source above
 */
export default {
  remedyCategories: {
    collectStormwater: {
      id: 'collectStormwater',
      color: 'aquafer',
      articleId: 'oYg4EasPSH2zXsCeunwqdW',
    },
    plantVegetation: {
      id: 'plantVegetation',
      color: 'sprout',
      articleId: '6Q5J56LCLUg84jgXUEjHta',
    },
    improveTransit: {
      id: 'improveTransit',
      color: 'mallow',
      articleId: '2KW8VRZJfUcpQgtSwFxpmJ',
    },
    improvePublicSpace: {
      id: 'improvePublicSpace',
      color: 'phacelia',
      articleId: '5Fb2uh4xnAFhBoQjHEfGYp',
    },
    decreaseWaterUse: {
      id: 'decreaseWaterUse',
      color: 'sunset',
      articleId: '1emeU3qJ6nu95MRyaWjnM4',
    },
    restoreWaterBody: {
      id: 'restoreWaterBody',
      color: 'lupine',
      articleId: 'n5ctMYQGpegA9Y5FHZHXkZ',
    },
    audio: {
      id: 'audio',
      color: 'poppy',
    },
    video: {
      id: 'video',
      color: 'poppy',
    },
    info: {
      id: 'info',
      color: 'poppy',
    },
  },
}
