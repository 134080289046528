<template>
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5256 6.50563C16.7893 6.76935 16.9375 7.12704 16.9375 7.5C16.9375 7.87296 16.7893 8.23065 16.5256 8.49437L10.9006 14.1194C10.3514 14.6685 9.46106 14.6685 8.91188 14.1194C8.36271 13.5702 8.36271 12.6798 8.91188 12.1306L12.1363 8.90625L1.46875 8.90625C0.692101 8.90625 0.0625 8.27665 0.0625 7.5C0.0625 6.72335 0.692101 6.09375 1.46875 6.09375L12.1363 6.09375L8.91188 2.86937C8.36271 2.3202 8.36271 1.42981 8.91188 0.88063C9.46105 0.331459 10.3514 0.331459 10.9006 0.88063L16.5256 6.50563Z"
      fill="#3A3D40"
    />
  </svg>
</template>
