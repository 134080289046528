/**
 * Indicates which ambient audio is to be played for each section.
 */
export default {
  none: {},
  title: {},
  whereAreWe: {},
  history: {},
  intentToHeal: {},
  watershedContext: {},
  invitationToExplore: { ambience: 'mjp/invitation-audio.mp3' },
  waterTreatmentFacility: {
    ambience: 'mjp/waterTreatmentFacility-audio-fade.mp3',
  },
  wetlands: { ambience: 'mjp/wetlands-audio-fade.mp3' },
  lakes: { ambience: 'mjp/lakes-audio-fade.mp3' },
  streamBridgeAndTrail: { ambience: 'mjp/streamBridgeAndTrail-audio-fade.mp3' },
  splashPad: { ambience: 'mjp/splashPad-audio-fade.mp3' },
  communityCenter: {},
  conclusion: { ambience: 'mjp/invitation-audio.mp3' },
  projectSummary: {},
  projectCredits: { ambience: 'mjp/invitation-audio.mp3' },
  settings: {},
}
