export default function useVisibleHotspotCount(hotspotData, hotspotRefsById) {
  let visibleHotspots = 0
  let hotspotId
  for (let i = 0; i < hotspotData.length; i++) {
    hotspotId = hotspotData[i].id
    if (hotspotRefsById[hotspotId]) {
      if (hotspotRefsById[hotspotId].state.isVisible) {
        visibleHotspots++
      }
    }
  }
  return visibleHotspots
}
