<template>
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.55927 12.322V6.22031C8.55927 5.00661 9.04141 3.84262 9.89963 2.9844C10.7578 2.12618 11.9218 1.64404 13.1355 1.64404C14.3492 1.64404 15.5132 2.12618 16.3715 2.9844C17.2297 3.84262 17.7118 5.00661 17.7118 6.22031V12.322C17.7118 13.5357 17.2297 14.6997 16.3715 15.5579C15.5132 16.4161 14.3492 16.8983 13.1355 16.8983C11.9218 16.8983 10.7578 16.4161 9.89963 15.5579C9.04141 14.6997 8.55927 13.5357 8.55927 12.322ZM20.7627 12.322C20.7627 12.1197 20.6823 11.9257 20.5393 11.7827C20.3962 11.6397 20.2022 11.5593 19.9999 11.5593C19.7977 11.5593 19.6037 11.6397 19.4606 11.7827C19.3176 11.9257 19.2372 12.1197 19.2372 12.322C19.2372 13.9403 18.5944 15.4923 17.4501 16.6366C16.3058 17.7809 14.7538 18.4237 13.1355 18.4237C11.5173 18.4237 9.96528 17.7809 8.82099 16.6366C7.6767 15.4923 7.03385 13.9403 7.03385 12.322C7.03385 12.1197 6.95349 11.9257 6.81045 11.7827C6.66742 11.6397 6.47342 11.5593 6.27113 11.5593C6.06885 11.5593 5.87485 11.6397 5.73182 11.7827C5.58878 11.9257 5.50842 12.1197 5.50842 12.322C5.51075 14.2121 6.21386 16.0343 7.48179 17.4361C8.74972 18.8378 10.4924 19.7196 12.3728 19.911V22.2373C12.3728 22.4395 12.4532 22.6335 12.5962 22.7766C12.7393 22.9196 12.9333 23 13.1355 23C13.3378 23 13.5318 22.9196 13.6749 22.7766C13.8179 22.6335 13.8983 22.4395 13.8983 22.2373V19.911C15.7787 19.7196 17.5214 18.8378 18.7893 17.4361C20.0572 16.0343 20.7603 14.2121 20.7627 12.322Z"
      fill="#3A3D40"
    />
  </svg>
</template>
