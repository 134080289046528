import english from '@/i18n/english.js'
import spanish from '@/i18n/spanish.js'

export const getTranslation = (language: string) => {
  if (language.toLowerCase() === 'english') {
    return english
  } else if (language.toLowerCase() === 'spanish') {
    return spanish
  } else {
    throw new Error(`language ${language} not found`)
  }
}
