/**
 * Contains data on the images for each section for which preloading is to be tracked
 * by the MediaLoader component. Sections are indexed by id, and each contains an
 * array of image ids to be tracked. Each image to be tracked must have the
 * useImageLoad composable set as the handler for its onload event.
 */
export default {
  title: [
    'title-bg',
    'thumbnailStartFromBeginning-panelBtn-img',
    'thumbnailSkipToTour-panelBtn-img',
  ],
  whereAreWe: ['whereAreWe-bg'],
  history: [
    'history-bg',
    /*'1924-thumbnail',
    '1965-thumbnail',
    '1972-thumbnail',
    '1991-thumbnail',
    '2000-thumbnail',
    '2009-thumbnail',
    '2010-thumbnail',
    '2013-thumbnail',
    '2016-thumbnail',
    '2018-thumbnail',
    '2020-thumbnail',*/
  ],
  intentToHeal: ['intentToHeal-bg-1', 'intentToHeal-bg-2'],
  watershedContext: [
    'watershedContext-bg',
    'watersheda',
    'watersheda-mobile',
    'watershedb',
    'watershedc',
  ],
  invitationToExplore: ['invitationToExplore-bg'],
  waterTreatmentFacility: [
    'waterTreatmentFacility-bg',
    'waterTreatmentFacility-illo',
    'waterstation-still',
    'waterstation-animation',
    'waterstation2-still',
    'waterstation2-animation',
  ],
  wetlands: [
    'wetlands-bg',
    'wetlands-illo',
    'filterChemicals-thumbnail',
    'whatMicrobes-thumbnail',
    'wingedFriends-thumbnail',
    'slowIncomingWater-thumbnail',
    'wetlandsOverview-still',
    'wetlandsOverview-animation',
    'filtration-still',
    'filtration-animation',
    'birds-still',
    'birds-animation',
    'stormwater-still',
    'stormwater-animation',
    'gabion-still',
    'gabion-animation',
  ],
  lakes: [
    'lakes-bg',
    'lakes-illo',
    'oakTrees-thumbnail',
    'permeablePathway-thumbnail',
    'takeALakeBreak-thumbnail',
    'irrigation-still',
    'irrigation-animation',
    'oakTrees-still',
    'oakTrees-animation',
    'pathway-still',
    'pathway-animation',
    'takeABreak-still',
    'takeABreak-animation',
    'lake-still',
    'lake-animation',
  ],
  streamBridgeAndTrail: [
    'streamBridgeAndTrail-bg',
    'streamBridgeAndTrail-illo',
    'whyStreamHere-thumbnail',
    'californiaNatives-thumbnail',
    'stoppingPoint-thumbnail',
    'sourceOfShelter-thumbnail',
    'stream-still',
    'stream-animation',
    'nativePlants-still',
    'nativePlants-animation',
    'bridge-still',
    'bridge-animation',
    'trees-still',
    'trees-animation',
  ],
  splashPad: [
    'splashPad-bg',
    'splashPad-illo',
    'takeABreather-thumbnail',
    'shade-still',
    'shade-animation',
    'splashPad-still',
    'splashPad-animation',
    'bench-still',
    'bench-animation',
  ],
  communityCenter: [
    'communityCenter-bg',
    'communityCenter-illo',
    'carlaJayHarris-thumbnail',
    'mural-still',
    'mural-animation',
    'cheer-still',
    'cheer-animation',
    'directions-still',
    'directions-animation',
    'cooking-still',
    'cooking-animation',
  ],
  conclusion: ['conclusion-bg', 'conclusion-illo'],
  projectSummary: [
    'summary-bg',
    'thumbnailExploreUses-panelBtn-img',
    'thumbnailRequestAccess-panelBtn-img',
  ],
  projectCredits: ['credits-bg'],
  settings: [],
}
