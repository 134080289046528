import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import '@/assets/tailwind.css'

const pinia = createPinia()

const app = createApp(App)

app.directive('resize', {
  mounted: (el, binding) => {
    const onResizeCallback = binding.value
    window.addEventListener('resize', () => {
      const width = document.documentElement.clientWidth
      const height = document.documentElement.clientHeight
      onResizeCallback({ width, height })
    })
  },
})

app.use(router).use(pinia)

app.mount('#app')
