<template>
  <svg
    width="21"
    height="14"
    viewBox="0 0 21 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 2.1875V11.8125C15.5 12.1772 15.3551 12.5269 15.0973 12.7848C14.8394 13.0426 14.4897 13.1875 14.125 13.1875H1.75C1.38533 13.1875 1.03559 13.0426 0.777728 12.7848C0.519866 12.5269 0.375 12.1772 0.375 11.8125V2.1875C0.375 1.82283 0.519866 1.47309 0.777728 1.21523C1.03559 0.957366 1.38533 0.8125 1.75 0.8125H14.125C14.4897 0.8125 14.8394 0.957366 15.0973 1.21523C15.3551 1.47309 15.5 1.82283 15.5 2.1875ZM20.4844 2.20898C20.3874 2.18525 20.2865 2.18232 20.1883 2.20039C20.0901 2.21846 19.9968 2.25712 19.9146 2.31383L17.028 4.23797C16.9809 4.26939 16.9423 4.31195 16.9156 4.36187C16.8889 4.41179 16.875 4.46753 16.875 4.52414V9.47586C16.875 9.53247 16.8889 9.58821 16.9156 9.63813C16.9423 9.68805 16.9809 9.73061 17.028 9.76203L19.9309 11.6973C20.0396 11.7698 20.1667 11.8099 20.2972 11.8127C20.4278 11.8156 20.5565 11.7813 20.6683 11.7137C20.7717 11.6479 20.8565 11.5568 20.9146 11.4489C20.9726 11.341 21.0021 11.22 21 11.0975V2.875C21.0001 2.72252 20.9495 2.57435 20.8561 2.45378C20.7628 2.33322 20.632 2.2471 20.4844 2.20898Z"
      fill="#3A3D40"
    />
  </svg>
</template>
