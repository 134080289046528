<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.39356 10.1067C8.65141 10.3645 8.99069 10.5002 9.35711 10.5002V17.2858C9.35711 18.0051 9.6421 18.6972 10.1578 19.1993C10.6735 19.715 11.3521 20 12.0713 20C12.4377 20 12.777 19.8507 13.0349 19.6064C13.2927 19.3622 13.4284 19.0093 13.4284 18.6429C13.4284 18.2765 13.2792 17.9372 13.0349 17.6793C12.7906 17.4215 12.4377 17.2858 12.0713 17.2858V10.5002C12.0713 9.78096 11.7863 9.08884 11.2706 8.58671C10.7549 8.08458 10.0764 7.78601 9.35711 7.78601C8.99069 7.78601 8.65141 7.93529 8.39356 8.17957C8.13571 8.42385 8 8.7767 8 9.14312C8 9.50954 8.14928 9.84882 8.39356 10.1067Z"
      fill="#2E3133"
    />
    <path
      d="M10.0357 5.07185C10.4428 5.07185 10.8364 4.94971 11.1621 4.73257C11.5013 4.51543 11.7592 4.18973 11.9085 3.82331C12.0578 3.45689 12.0985 3.03618 12.0306 2.64262C11.9492 2.24906 11.7592 1.88264 11.4742 1.59765C11.1892 1.31265 10.8228 1.12266 10.4292 1.04123C10.0357 0.959804 9.62853 1.00052 9.26211 1.1498C8.89569 1.29908 8.56999 1.5705 8.33928 1.90978C8.12214 2.24906 8 2.64262 8 3.03618C8 3.57903 8.21714 4.09473 8.59713 4.47472C8.97712 4.85471 9.49282 5.07185 10.0357 5.07185Z"
      fill="#2E3133"
    />
  </svg>
</template>
