<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.12132 0.87868C3.94975 -0.292893 2.05025 -0.292893 0.87868 0.87868C-0.292893 2.05025 -0.292893 3.94975 0.87868 5.12132L15.7574 20L0.87868 34.8787C-0.292893 36.0503 -0.292893 37.9497 0.87868 39.1213C2.05025 40.2929 3.94975 40.2929 5.12132 39.1213L20 24.2426L34.8787 39.1213C36.0503 40.2929 37.9497 40.2929 39.1213 39.1213C40.2929 37.9497 40.2929 36.0503 39.1213 34.8787L24.2426 20L39.1213 5.12132C40.2929 3.94975 40.2929 2.05025 39.1213 0.87868C37.9497 -0.292893 36.0503 -0.292893 34.8787 0.87868L20 15.7574L5.12132 0.87868Z"
      fill="#F1EEE9"
    />
  </svg>
</template>
