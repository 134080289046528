/**
 * Contains data for each stop of the MJP timeline. For details on the stop
 * data format, see the TimelineItem interface.
 */
export default [
  {
    id: 'athensTankFarm',
    date: '1924-01-02',
    popover: {
      direction: 'top',
      alignment: 'right',
      text: 'text',
      thumbnail: '1924-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'exxonMobil',
    date: '1965-01-02',
    popover: {
      direction: 'top',
      alignment: 'center',
      text: 'text',
      thumbnail: '1965-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'ujimaVillage',
    date: '1972-01-02',
    popover: {
      direction: 'top',
      alignment: 'center',
      text: 'text',
      thumbnail: '1972-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'magicJohnson',
    date: '1991-01-02',
    popover: {
      direction: 'top',
      alignment: 'center',
      text: 'text',
      thumbnail: '1991-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'hudSale',
    date: '1995-01-02',
    popover: {
      direction: 'top',
      alignment: 'center',
      text: 'text',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'investigationResults',
    date: '2000-01-02',
    popover: {
      direction: 'top',
      alignment: 'center',
      text: 'text',
      thumbnail: '2000-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'remediationBegins',
    date: '2008-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'ujimaVillageCloses',
    date: '2009-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
      thumbnail: '2009-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'allegationsFiled',
    date: '2010-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
      thumbnail: '2010-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'ujimaVillageDemolished',
    date: '2013-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
      thumbnail: '2013-thumbnail.jpg',
      thumbnailAlt: 'alt',
    },
  },
  {
    id: 'plansApproved',
    date: '2016-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
      thumbnail: '2016-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'renovationBegins',
    date: '2018-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
      thumbnail: '2018-thumbnail.jpg',
      thumbnailAlt: 'alt',
    },
  },
  {
    id: 'parkReopens',
    date: '2020-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
      thumbnail: '2020-thumbnail.jpg',
      thumbnailAlt: 'alt',
      action: {
        type: 'learnMore',
        text: 'learnMore',
        content: 'expanded',
      },
    },
  },
  {
    id: 'remediationContinues',
    date: '2022-01-02',
    popover: {
      direction: 'top',
      alignment: 'left',
      text: 'text',
    },
  },
]
