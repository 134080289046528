<template>
  <svg
    width="48"
    height="20"
    viewBox="0 0 48 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L24.7578 18L46 2"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>
