/**
 * Contains section, position, and remedy category data for each of the stops
 * on the MJP tour. Displayed in the OverheadMap component.
 */
export default [
  {
    section: 'waterTreatmentFacility',
    buttonPos: 'left-[44px] top-[724px]',
    panelPos: 'sm:right-[713px] sm:top-[670px]',
    remedyCategories: ['collectStormwater'],
  },
  {
    section: 'wetlands',
    buttonPos: '-left-[29px] top-[437px]',
    panelPos: 'sm:right-[786px] sm:top-[360px]',
    remedyCategories: [
      'plantVegetation',
      'restoreWaterBody',
      'collectStormwater',
    ],
  },
  {
    section: 'lakes',
    buttonPos: 'left-[272px] top-[165px]',
    panelPos: 'sm:right-[488px] sm:top-[4px]',
    remedyCategories: [
      'improveTransit',
      'plantVegetation',
      'collectStormwater',
      'improvePublicSpace',
    ],
  },
  {
    section: 'streamBridgeAndTrail',
    buttonPos: 'left-[569px] top-[100px]',
    panelPos: 'sm:left-[682px] sm:-top-[49px]',
    remedyCategories: ['improveTransit', 'plantVegetation', 'restoreWaterBody'],
  },
  {
    section: 'splashPad',
    buttonPos: 'left-[234px] top-[756px]',
    panelPos: 'sm:left-[458px] sm:top-[714px]',
    remedyCategories: ['improvePublicSpace'],
  },
  {
    section: 'communityCenter',
    buttonPos: 'left-[337px] top-[796px]',
    panelPos: 'sm:left-[458px] sm:top-[796px]',
    remedyCategories: ['improvePublicSpace'],
  },
]
