import { SectionSequence } from '@/components/magicjohnsonpark/types'

export default function useSectionIndex(section) {
  for (let i = 0; i < SectionSequence.length; i++) {
    if (SectionSequence[i].section == section) {
      return i
    }
  }
  return -1
}
