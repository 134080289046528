import { StyleValue } from 'vue'
import { MJPSection } from '../magicjohnsonpark/types'

export interface TimelineItem {
  id: string
  date: string
  percentage: number // this item's location on the timeline
  year: number
  pointsUp: boolean // direction of this item's stem
  popover: PopoverData
}

export interface ImageBackgroundState {
  translate: string // hotspot container translation as a css string
  scale: string // hotspot container scaling as a css string
  scaleRatio: number // numerical hotspot container scaling (0-1)
  hotspotSize: number // keeps the hotspots as a contant size no matter the scaling of the container
  naturalWidth: number // natural width of the background image
  naturalHeight: number // natural height of the background image
  proxyPosition: string // allows position to be modified by horizontal scroll (if any)
  offsetX: number // allows position of hotspot container to compensate for horizontal scroll (if any)
}

export enum PopoverActionType {
  GoToSection = 'goToSection',
  LearnMore = 'learnMore',
  PlayVideo = 'playVideo',
  PlayAudio = 'playAudio',
  Preview = 'preview',
}

export interface PopoverAction {
  type: PopoverActionType
  text?: string // text of the action displayed to user
  content?: string // content associated with the action (filename or text)
  width?: number // width of the content (for playVideo actions)
  height?: number // height of the content (for playVideo actions)
  destination?: string // id of the destination section (for goToSection actions)
  destinationText?: string // text of the destination link (for goToSection actions)
  videoUID?: object // key/value store of CloudFlare uids, by language code (for playVideo actions)
}

export enum ButtonVariant {
  LightOutline = 'lightOutline',
  DarkOutline = 'darkOutline',
  Filled = 'filled',
  FilledOutline = 'filledOutline',
}

export enum ButtonSizing {
  Default = 'default',
  Compact = 'compact',
}

export enum ButtonPanelSizing {
  Default = 'default',
  Large = 'large',
}

export interface AnimationData {
  id: string // unique animation id
  fileId?: string // file id (doesn't have to be unique)
  layout: StyleValue
  mute?: string // id of another animation to hide while this one plays (if they occupy the same space)
}

export interface PopoverActionEvent {
  event: MouseEvent
  action: PopoverAction
}

export interface TranscriptData {
  title: string
  text: string
}

export interface TranscriptPlaybackData {
  transcript: string
  x: number
  y: number
}

export interface PopoverData {
  remedyCategory: string
  direction: string // direction of the popover relative to the hotspot (top, right, bottom, left)
  alignment: string // alignment of the popover relative to the hotspot (top, bottom, left, right, center)
  text: string
  thumbnail: string
  thumbnailAlt: string // alt text for the thumbnail
  action: PopoverAction
  transcript: TranscriptData
}

export interface HotSpotData {
  id: string
  left: number
  top: number
  animation: AnimationData
  popover: PopoverData // full popover that appears on click
  preview: PopoverData // mini popover that appears on highlight or rollover
}

export interface ScrollOffset {
  proxyPosition: string
  offsetX: number
}

// data sent to the video player when a video is triggered for playback
export interface VideoPlaybackData {
  x: number
  y: number
  width: number
  height: number
  filename: string
  videoUID: string
}

export interface MediaLoadProgress {
  id: string
  progress: number
}

export interface SectionChangeData {
  section: MJPSection
  pageNumber: number
  forward: boolean // should the change be rendered as forward progress
  lastSection: MJPSection
}

export interface MenuItem {
  label: string
  value: string
}
