import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { nextTick } from 'vue'
import MagicJohnsonPark from '@/components/magicjohnsonpark/MagicJohnsonPark.vue'
import english from '@/i18n/english.js'

const defaultTitle = 'Living Infrastructure Primer'

declare module 'vue-router' {
  interface RouteMeta {
    title?: string
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:section?',
    redirect: () => {
      return 'after-the-rain'
    },
  },
  {
    path: '/after-the-rain/:section?',
    name: 'after-the-rain',
    meta: { title: english.title.heading },
    component: MagicJohnsonPark,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = defaultTitle + ' | ' + to.meta.title
  })
})

export default router
