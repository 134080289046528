<template>
  <svg
    width="25"
    height="22"
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.8968 0.00541712C17.0828 -0.0140264 17.2706 0.0191043 17.4388 0.101048C17.6071 0.183243 17.749 0.311074 17.8482 0.469975C17.9474 0.628876 18 0.812458 18 0.999798V24.9998C17.9999 25.1869 17.9472 25.3702 17.8481 25.5289C17.7489 25.6876 17.6072 25.8153 17.4391 25.8975C17.271 25.9796 17.0832 26.013 16.8971 25.9937C16.711 25.9745 16.534 25.9034 16.3862 25.7885L7.65625 18.9998H2C1.46957 18.9998 0.960857 18.7891 0.585785 18.414C0.210712 18.0389 0 17.5302 0 16.9998V8.9998C0 8.46936 0.210712 7.96066 0.585785 7.58558C0.960857 7.21051 1.46957 6.9998 2 6.9998H7.65625L16.3862 0.211048C16.5338 0.0961039 16.7107 0.0248644 16.8968 0.00541712ZM7 8.9998H2V16.9998H7V8.9998ZM9 17.511L16 22.9548V3.0448L9 8.48855V17.511Z"
      fill="#3A3D40"
    />
  </svg>
</template>
