import remedyData from '@/assets/data/remedy-data.js'

const bgColors = {
  sunset: 'bg-sunset',
  mallow: 'bg-mallow',
  sprout: 'bg-sprout',
  aquafer: 'bg-aquafer',
  lupine: 'bg-lupine',
  manzanita: 'bg-manzanita',
  phacelia: 'bg-phacelia',
  poppy: 'bg-poppy',
}

export default function useRemedyCategoryBackgroundColor(category) {
  const categoryData = remedyData.remedyCategories[category]
  if (categoryData) {
    const color = remedyData.remedyCategories[category].color
    if (color) {
      return bgColors[color]
    }
  }
  return 'bg-slateGray'
}
