<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" width="2" height="12" rx="1" fill="#3A3D40" />
    <rect x="6" width="2" height="12" rx="1" fill="#3A3D40" />
  </svg>
</template>
