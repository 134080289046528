<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 25"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9169 10.046L21.0774 10.5972L21.6344 10.7365L23.9995 11.3279V13.6726L21.6344 14.264L21.0774 14.4032L20.9169 14.9544C20.7369 15.5724 20.4907 16.1626 20.1867 16.7157L19.91 17.2192L20.2056 17.7118L21.4604 19.8034L19.8028 21.4609L17.7111 20.2061L17.2184 19.9105L16.7149 20.1874C16.1616 20.4916 15.5719 20.7374 14.9538 20.9174L14.4026 21.078L14.2633 21.6349L13.6719 24H11.3272L10.7357 21.6345L10.5964 21.0775L10.0452 20.917C9.42728 20.737 8.83707 20.4908 8.28392 20.1868L7.78049 19.9101L7.28789 20.2057L5.19622 21.4605L3.53865 19.8029L4.79345 17.7113L5.08907 17.2185L4.81218 16.715C4.50793 16.1617 4.26219 15.5721 4.08215 14.954L3.92159 14.4028L3.36462 14.2635L0.999512 13.6721V11.3274L3.36462 10.736L3.92159 10.5968L4.08215 10.0455C4.26214 9.42761 4.50833 8.83741 4.8123 8.28428L5.08898 7.78083L4.79345 7.28821L3.53865 5.19661L5.19622 3.53908L7.28789 4.79387L7.78064 5.08947L8.28415 4.8126C8.83744 4.50836 9.42708 4.26262 10.0452 4.08258L10.5964 3.92203L10.7357 3.36506L11.3271 1H13.6718L14.2633 3.36553L14.4026 3.9225L14.9538 4.08305C15.5717 4.26304 16.162 4.50922 16.7151 4.8132L17.2185 5.08985L17.7111 4.79434L19.8028 3.53955L21.4604 5.19708L20.2056 7.28868L19.91 7.78145L20.1868 8.28498C20.4911 8.83825 20.7368 9.42788 20.9169 10.046ZM24.0176 13.6681L24.0173 13.6681L24.0176 13.6681ZM5.18061 3.52972L5.18082 3.52984L5.18061 3.52972ZM7.59411 12.5002C7.59411 15.206 9.79358 17.406 12.5 17.406C15.2058 17.406 17.4058 15.2061 17.4058 12.5002C17.4058 9.79439 15.2058 7.59444 12.5 7.59444C9.79413 7.59444 7.59411 9.79439 7.59411 12.5002Z"
      stroke-width="2"
    />
  </svg>
</template>
