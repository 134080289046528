import { MJPSection } from '@/components/magicjohnsonpark/types'

export default function useSectionToTourIndex(section) {
  switch (section) {
    case MJPSection.WaterTreatmentFacility:
      return 0
    case MJPSection.Wetlands:
      return 1
    case MJPSection.Lakes:
      return 2
    case MJPSection.StreamBridgeAndTrail:
      return 3
    case MJPSection.SplashPad:
      return 4
    case MJPSection.CommunityCenter:
      return 5

    default:
      return -1
  }
}
