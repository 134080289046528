import LakeShapeVue from '@/assets/svg/LakeShape.vue'
import ArrowButtonVue from '@/components/general/ArrowButton.vue'
import HotSpotVue from '@/components/general/HotSpot.vue'
import HotspotContainerVue from '@/components/general/HotspotContainer.vue'
import PopOverVue from '@/components/general/PopOver.vue'
import RemedyCategoryBadgeVue from '@/components/general/RemedyCategoryBadge.vue'
import SiteStopButtonVue from '@/components/general/SiteStopButton.vue'
import OverheadMapVue from '@/components/magicjohnsonpark/OverheadMap.vue'

export default function useTabIndex(component, index, subComponent, subIndex) {
  let baseIndex = 0
  let interval = 10
  switch (component) {
    case OverheadMapVue:
      baseIndex = 200
      switch (subComponent) {
        case SiteStopButtonVue:
          return baseIndex + (index + 1) * interval
        case LakeShapeVue:
          return baseIndex + 80
        case ArrowButtonVue:
          return baseIndex + (index + 1) * interval + subIndex + 1
        case RemedyCategoryBadgeVue:
          return baseIndex + (index + 1) * interval + subIndex + 1
      }
      break

    case PopOverVue:
      baseIndex = index
      switch (subComponent) {
        case 'actionLink':
          return baseIndex + 1
        case 'video':
        case 'audio':
          return baseIndex + 2
        case 'expandedText':
        case 'transcript':
          return baseIndex + 3
        case 'closeBtn':
          return baseIndex + 4
      }
      break

    case HotspotContainerVue:
      baseIndex = 0
      interval = 20
      switch (subComponent) {
        case HotSpotVue:
          return baseIndex + (index + 1) * interval
        case PopOverVue:
          return baseIndex + (index + 1) * interval + subIndex + 1
      }
      break
  }
  return -1
}
