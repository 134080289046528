import { Component } from 'vue'
import HistorySectionVue from './HistorySection.vue'
import IntentToHealSectionVue from './IntentToHealSection.vue'
import TitleSectionVue from './TitleSection.vue'
import WatershedContextVue from './WatershedContext.vue'
import WaterTreatmentFacilitySectionVue from './WaterTreatmentFacilitySection.vue'
import WetlandsSectionVue from './WetlandsSection.vue'
import InvitationToExploreVue from './InvitationToExplore.vue'
import LakesSectionVue from './LakesSection.vue'
import StreamBridgeAndTrailSectionVue from './StreamBridgeAndTrailSection.vue'
import SplashPadSectionVue from './SplashPadSection.vue'
import CommunityCenterSectionVue from './CommunityCenterSection.vue'
import ConclusionSectionVue from './ConclusionSection.vue'
import ProjectSummarySectionVue from './ProjectSummarySection.vue'
import ProjectCreditsSectionVue from './ProjectCreditsSection.vue'

export enum MJPSection {
  None = 'none',
  Title = 'title',
  History = 'history',
  IntentToHeal = 'intentToHeal',
  WatershedContext = 'watershedContext',
  InvitationToExplore = 'invitationToExplore',
  WaterTreatmentFacility = 'waterTreatmentFacility',
  Wetlands = 'wetlands',
  Lakes = 'lakes',
  StreamBridgeAndTrail = 'streamBridgeAndTrail',
  SplashPad = 'splashPad',
  CommunityCenter = 'communityCenter',
  Conclusion = 'conclusion',
  ProjectSummary = 'projectSummary',
  ProjectCredits = 'projectCredits',
  Settings = 'settings',
}

export enum OverheadMapState {
  Hidden = 'Hidden',
  Small = 'Small',
  Large = 'Large',
}

export interface MJPSectionData {
  section: MJPSection
  component: Component
  goBackwardsToPreviousSection: boolean // when backing up to the previous section, should we arrive at the end of that section?
  pageMap: Array<number> // how many screen heights does each "page" of the section occupy?
  focalPoint: string // when the browser window is resized, keep this point centered (expressed as horizontal and vertical percentages)
  initialFocus?: string // id of the element to receive focus when section loads
}

// determines the overall order and structure of the experience
export const SectionSequence: Array<MJPSectionData> = [
  {
    section: MJPSection.Title,
    component: TitleSectionVue,
    goBackwardsToPreviousSection: false,
    pageMap: [1],
    focalPoint: '50% 50%',
    initialFocus: 'desktop-living-infra-link',
  },
  {
    section: MJPSection.History,
    component: HistorySectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 1],
    focalPoint: '50% 50%',
  },
  {
    section: MJPSection.IntentToHeal,
    component: IntentToHealSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1],
    focalPoint: '50% 50%',
  },
  {
    section: MJPSection.WatershedContext,
    component: WatershedContextVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 1],
    focalPoint: '50% 50%',
  },
  {
    section: MJPSection.InvitationToExplore,
    component: InvitationToExploreVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1],
    focalPoint: '50% 50%',
  },
  {
    section: MJPSection.WaterTreatmentFacility,
    component: WaterTreatmentFacilitySectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 3],
    focalPoint: '50% 53%',
  },
  {
    section: MJPSection.Wetlands,
    component: WetlandsSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 5],
    focalPoint: '72% 59%',
  },
  {
    section: MJPSection.Lakes,
    component: LakesSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 1, 5],
    focalPoint: '52% 73%',
  },
  {
    section: MJPSection.StreamBridgeAndTrail,
    component: StreamBridgeAndTrailSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 1, 5],
    focalPoint: '50% 62%',
  },
  {
    section: MJPSection.SplashPad,
    component: SplashPadSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 1, 3],
    focalPoint: '100% 60%',
  },
  {
    section: MJPSection.CommunityCenter,
    component: CommunityCenterSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 1, 4],
    focalPoint: '25% 50%',
  },
  {
    section: MJPSection.Conclusion,
    component: ConclusionSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [1, 1, 1, 1],
    focalPoint: '50% 50%',
  },
  // {
  //   section: MJPSection.ProjectSummary,
  //   component: ProjectSummarySectionVue,
  //   goBackwardsToPreviousSection: true,
  //   pageMap: [1],
  //   focalPoint: '50% 50%',
  // },
  {
    section: MJPSection.ProjectCredits,
    component: ProjectCreditsSectionVue,
    goBackwardsToPreviousSection: true,
    pageMap: [5],
    focalPoint: '50% 50%',
  },
]
