export default {
  language: 'English',
  remedyCategories: {
    collectStormwater: 'Stormwater',
    plantVegetation: 'Plants',
    improvePublicSpace: 'Amenities',
    reduceWaterUse: 'Reduce Water Use',
    restoreWaterBody: 'Water Bodies',
    improveTransit: 'Transit',
  },
  interface: {
    languageTag: 'EN',
    next: 'Next',
    learnMore: 'Learn more',
    showMore: 'Show more',
    showLess: 'Show less',
    backToStart: 'Back to Start',
    skipToTour: 'Skip to Walking Tour',
    skipToSummary: 'Skip to Credits',
    home: 'Home',
    returnToFieldKit: 'Return to Field Kit',
    globeAlt: 'An icon of a globe',
    startFromBeginning: 'History',
    skipToWalkingTour: 'Walking Tour',
    twentyMinuteRead: '20 minute read',
    twentyFiveMinuteRead: '25 minute read',
    backToWatershed: 'Jump back to Watershed section',
    backToWaterTreatmentFacility: 'Jump back to Stop 1',
    loaderAnimationAltText:
      'Two waterfowl standing in the shallows, one flaps its wings briefly.',
    scrollToHistory: 'Scroll to continue to history',
    scrollInstruction: 'Press to Continue',
    scrollToCredits: 'Scroll to view credits',
    settings: 'Settings',
    openTranscript: 'Open Transcript',
    audioTranscript: 'Audio Transcript',
    close: 'Close',
    muteAudio: 'Mute audio',
    parkOverview: 'Park overview',
    stop: 'Stop',
  },
  title: {
    heading: 'After the Rain',
    paragraphOne:
      'Let’s take a walk to see how one community’s relationship to water is helping to heal old wounds.',
    paragraphTwoA: 'See how',
    livingInfrastructure: 'living infrastructure',
    paragraphTwoB:
      'is helping to correct the downstream effects of decades of misuse at Magic Johnson Park. You’ll learn how built, natural, and social remedies work together to heal the land and the community.',
    paragraphCompactA: 'Let’s take a walk to see how',
    paragraphCompactB:
      'is helping to correct the downstream effects of decades of misuse at Magic Johnson Park.',
    altText:
      'A renovated walkway extends out into Magic Johnson Park. A clear lake, wetland grasses and healthy trees surround the path.',
    illoAltText: 'Grass waves, water sparkles, and birds stretch their wings.',
    startFromBeginningAltText:
      'Workers walk past dozens of drilling rigs in an oilfield in Los Angeles in the early 1920s.',
    skipToWalkingTourAltText:
      'A map pinpoints six locations for a walking tour around Magic Johnson Park. The tour stops are the Stormwater Treatment Station, Wetlands, South Lake, Stream Bridge and Trail, Splash Pad, and Community Center.',
  },
  history: {
    photoCredit:
      'Courtesy of the California History Room, California State Library, Sacramento, California',
    willowbrook:
      'The unincorporated community of Willowbrook — and the park at its heart — needed a change.',
    card: 'In the early 1920s, the site currently known as Magic Johnson Park was used to store and distribute petroleum products.',
    rippleEffects:
      'The ripple effects of the mistreatment of land are still being felt nearly a century later.',
    willowbrookAlt:
      'An aerial perspective of the Willowbrook community in Los Angeles. The blocks of houses and apartments have manicured lawns.',
    altText:
      'Workers walk past dozens of drilling rigs in an oilfield in Los Angeles in the early 1920s.',
    clickTheYears: 'Click the years to learn more',
  },
  intentToHeal: {
    investment:
      'In 2014, the county invested $135 million in a multi-year renovation project intended to help heal the site from past misuse. Plans included reviving the water, new spaces for the community to gather, and planting hundreds of trees.',
    impact:
      'The impact of the project extends beyond the boundaries of the 126-acre park, too.',
    visitorDogAlt:
      'A visitor walks their dog past the lake and community center.',
    wideViewAlt: 'A wide view of Magic Johnson Park and one of its lakes.',
  },
  watershedContext: {
    location:
      'Magic Johnson Park is situated within the 42.1 square mile Compton Creek Watershed, a subwatershed of the LA River Watershed.',
    before:
      'Before the renovation, stormwater and dry runoff from the surrounding community were flushed to Compton Creek. This water eventually drained into San Pedro Bay — along with all the pollution it picked up.',
    today: 'Today, the water is diverted to Magic Johnson Park.',
    firstStop:
      'The first stop on our tour follows the diverted stormwater into the park.',
    watershedAnimationAlt:
      'Rain falls on the Los Angeles River watershed, draining into the Pacific Ocean.',
    comptonCreekBeforeAnimationAlt:
      'Rain falls on the Compton Creek Watershed, draining into the Los Angeles River.',
    comptonCreekAfterAnimationAlt:
      'Rain falls on the Compton Creek Watershed, with significant stormwater collection happening at Magic Johnson Park.',
    watershedDiagramAlt:
      'Map of the Los Angeles River Watershed, which surrounds the boundaries of Compton Creek Watershed, Compton Creek, Los Angeles River, Downtown Los Angeles and Magic Johnson Park.',
    comptonCreekDiagramAlt:
      'Map of Compton Creek Watershed, which surrounds the boundaries of Magic Johnson Park, Willowbrook Community Watershed and Compton Creek.',
    altText:
      'Debris and plastic trash are scattered around the water and plants of Compton Creek.',
    impact:
      'The impact of the project extends beyond the boundaries of the 126-acre park, to the entire Los Angeles River Watershed.',
    whatIsAWatershed: '...but what is a watershed, exactly?',
    altTextRedux:
      'An overhead perspective of Magic Johnson Park within the larger Los Angeles area displays the stormwater treatment station, wetlands, walking paths, a lake, a community center, and green parks, among other features.',
  },
  invitationToExplore: {
    takeWalk: 'Let’s take a walk to explore the park.',
    altText:
      'A renovated walkway extends out into Magic Johnson Park. A clear lake, wetland grasses and healthy trees surround the path.',
  },
  overheadMap: {
    stops: [
      {
        title: 'Water Treatment Facility',
        body: 'Learn where the water at Magic Johnson Park comes from, how it’s treated, and how it’s reused.',
      },
      {
        title: 'Wetlands',
        body: 'See how wetlands help to nourish Magic Johnson Park. Learn which birds call this place home, explore how microbes are working to filter the lake water, and learn why there’s a rock wall running along the edge of the lake.',
      },
      {
        title: 'Lakes',
        body: 'Learn about how the lakes hold water and protect the surroundings, as well as how permeable pathways help to replenish groundwater. You’ll also be able to take a calming breather on a peaceful bench.',
      },
      {
        title: 'Stream Bridge and Trail',
        body: 'Explore the many trees of Magic Johnson Park, learn which California native plants are healing the landscape, and watch how the stream and accompanying bridge facilitate movement.',
      },
      {
        title: 'Splash Pad',
        body: 'See how the splash pad at the park brings joy to the community.',
      },
      {
        title: 'Community Center',
        body: 'Explore how the center acts as a gathering space.',
      },
    ],
  },
  timeline: {
    athensTankFarm: {
      title: 'Athens Tank Farm',
      text: 'The Athens Tank Farm houses pipelines, storage tanks, and 2 large crude-oil reservoirs.',
      expanded:
        'The 122-acre site is purchased by General Petroleum Company. It is operated by Mobil Oil Corporation-related entities until 1963. Activities include running a “tank farm” used for the storage and distribution of petroleum products. Facilities include twenty-two 80,000-barrel above-ground storage tanks, two concrete-lined reservoirs, and equipment to pump and circulate petroleum products throughout the facility. Crude oil, gasoline, and diesel fuel are some of the petroleum products stored at the tank farm. Throughout its nearly four decades of operation, residential housing continues to develop in the area surrounding the tank farm. ExxonMobil Oil Corporation is the last commercial owner of the property.',
      alt: 'Aerial overview of the Athens Tank Farm, which is surrounded by blocks of residential units.',
    },
    exxonMobil: {
      title: 'Exxon Mobil vacates',
      text: 'Exxon Mobil vacates the area.',
      expanded:
        'Between 1962 and 1965, the facilities are removed from the site, and the property is sold to a private developer called the DeLay Land Company. No testing is done to reveal that the air, groundwater, and soil are contaminated. This information will not become known to the community for decades.',
      alt: 'Aerial overview of the vacated Athens Tank Area. The ground is blackened by contamination, but is still surrounded by blocks of residential units.',
    },
    ujimaVillage: {
      title: 'Ujima Village opens',
      text: 'Ujima Village opens. “The village” was a 300-unit apartment complex.',
      expanded:
        'The designs in the original masterplan cover almost the entire site and include extensive housing, a 10-acre shopping complex, a child care center, and an elementary school. The project is recognized by the LA Times as the first of its magnitude to be undertaken by and for the Black community. However, the ambitious goals of the masterplan are thwarted by funding issues - the apartment complex becomes the only piece of the plan built to fruition. This 300-unit complex is designed by two Black architecture firms: Kinsey, Meeds, and Williams, and John D. Williams Associates. It is planned in close collaboration with a coalition of over 50 Black community organizations. “Ujima” means group effort and responsibility in Swahili, and the apartment complex’s opening is lauded as a triumph for working-class Black families. The surrounding area becomes a park named the “Willowbrook Recreation Area.”',
      alt: 'A worker pushes a wheelbarrow through the under-construction Ujima Village houses.',
    },
    magicJohnson: {
      title: 'The park is renamed',
      text: 'The park is renamed after Earvin “Magic” Johnson by  LA County.',
      expanded:
        '<em>“Hailing Earvin (Magic) Johnson as a ‘national hero,’ the Los Angeles County Board of Supervisors on Tuesday unanimously approved renaming the Willowbrook Recreation Area in honor of the retired Los Angeles Lakers star.</em><br/><br/><em>“Supervisor Kenneth Hahn, who proposed the change, praised Johnson as a ‘hero in the field of basketball’ and ‘a great role model’ for young people.”</em> - LA Times',
      alt: 'Newspaper clipping entitled “County Park Named for Magic Johnson”.',
    },
    hudSale: {
      title: 'Ujima Village sold',
      text: 'HUD sells the Ujima Village Apartment Complex to the Los Angeles Housing Authority.',
      expanded:
        'A mortgage default results in foreclosure and HUD takes ownership of the property. Within a few years, HUD sells the property to the Los Angeles Housing Authority for $1. Prior to the sale of the apartment complex to the Housing Authority, HUD conducts an environmental investigation and finds that soils below the property contain chemicals from the tank farm operations. However, HUD claims that their presence does not pose a significant health risk.',
      alt: 'A map outlines the boundaries of the former petroleum site and overlays environmental contamination.',
    },
    investigationResults: {
      title: 'Investigation results',
      text: 'Environmental investigations reveal that oil seeped into the ground during the site’s petroleum storage years.',
      expanded:
        'Subsequent testing reconfirms the presence of chemicals on the site. Concerned residents are reassured that their drinking water is safe and that the contaminated soil and groundwater are too far below the surface to cause health issues. Meanwhile, the apartment complex is suffering from decades of mismanagement and deterioration. When the county tries to sell the complex, the developers commission more soil and groundwater tests that again reveal the site is contaminated with gas and crude oil.',
      alt: 'A map outlines the boundaries of the former petroleum site and overlays environmental contamination.',
    },
    remediationBegins: {
      title: 'Remediation',
      text: 'Efforts to remediate the situation begin.',
      expanded:
        'The Los Angeles Regional Water Quality Control Board (part of the California Environmental Protection Agency) orders the Los Angeles Housing Authority and ExxonMobil to clean up the site. Further testing and cleanup activity continue for years and include soil vapor extraction, soil removal, monitoring wells, and other methods. In November 2008, LA County supervisors vote to close the complex and relocate residents.',
    },
    ujimaVillageCloses: {
      title: 'Ujima Village closes',
      text: 'Ujima Village closes down.',
      expanded:
        'Although the county continues to claim that environmental contamination is not a health issue for residents, they label the complex “blighted” due to asbestos and other building contamination issues. They order the occupants to be relocated, and afterwards the apartments are shuttered. Over 600 people are displaced.',
      alt: 'Newspaper clipping entitled “A Community is sundered: Tainted soil leads to closure of what was once a tight-knit complex”.',
    },
    allegationsFiled: {
      title: 'Lawsuit filed',
      text: 'Former Ujima Village residents sue.',
      expanded:
        'More than 400 former residents of Ujima Village file a lawsuit against the county, the site developers, and ExxonMobil. They claim they were intentionally left in the dark about pollution risks, and that contamination issues caused cancer, asthma, and other health problems that resulted in 38 deaths.',
      alt: 'Newspaper clipping entitled “Hundreds sue L.A. County over Ujima Village contamination”.',
    },
    ujimaVillageDemolished: {
      title: 'Ujima Village demolished',
      text: 'The Ujima Village housing units are demolished.',
      alt: 'Padlocked fences surround the abandoned Ujima Village complex.',
    },
    plansApproved: {
      title: 'Plans approved',
      text: 'Plans for the renovation of the park are approved.',
      expanded:
        'Los Angeles Department of Parks and Recreation conducts a needs assessment, which determines that Willowbrook is “park poor” - meaning there is a high need for green space. The department works with Willowbrook community members to come up with a masterplan for park expansion (including the former Ujima Village land footprint) and improvement. The masterplan is approved by the LA County Board of Supervisors in February 2016.',
      alt: 'A community meets to discuss the renovation plans for Magic Johnson Park.',
    },
    renovationBegins: {
      title: 'Renovation begins',
      text: 'The extensive renovation of Magic Johnson Park begins.',
      alt: 'A map of the renovated Magic Johnson Park.',
    },
    parkReopens: {
      title: 'Park reopens',
      text: 'The park reopens.',
      expanded:
        'The first phase of the park renovation features 300 new trees, 30,000 plants, a new stormwater system, and a community center. Magic Johnson himself attends the ribbon-cutting ceremony. The park is celebrated as one of the largest green spaces in South Los Angeles.',
      alt: 'ABC7 news story entitled “Sneak peek at Magic Johnson Park after $70 million renovations completed”.',
    },
    remediationContinues: {
      title: 'Remediation continues',
      text: 'Exxon continues to monitor the site for methane and other contaminants.',
    },
  },
  waterTreatmentFacility: {
    title: 'Water Treatment Facility',
    waterCollected:
      'Water collected from local storm drains passes through this station.',
    waterScrubbed:
      'Here, the water is scrubbed of bacteria and other contaminants before being pumped into the lake.',
    waterTreated:
      'Inside, water is treated with alum and ozone to kill bacteria.',
    whatsGoingOn: 'What’s going on inside this building?',
    waterSource: 'Where does the water come from?',
    howDoesParkRedirect:
      'How does Magic Johnson Park redirect stormwater within the Compton Creek watershed?',
    willowbrookWaterSource:
      'The community of Willowbrook gets its water from a variety of local and remote sources. Some of its water comes right from the Central Basin - a network of aquifers that hold groundwater below Los Angeles. The rest flows in from the Colorado River Aqueduct and the State Water Project, traveling hundreds of miles through canals, pipelines, and reservoirs before reaching residents, where folks use it for showers, cooking, washing dishes, and more. After being used, the dirty water is sent to a wastewater treatment plant, and ultimately, is flushed out to the ocean.',
    pumpingStation:
      'A 7-foot diameter drain pipe runs under El Segundo Boulevard, on the southern edge of Magic Johnson Park. This pipe transports dry runoff (a term used to describe non-stormwater, like irrigation, or the water used for washing a car in the driveway) as well as stormwater to the pumping station at Magic Johnson Park. Here, the water undergoes a first round of cleaning to remove large debris and trash, like potato chip bags and plastic drinking straws, before heading to the park’s treatment facility.',
    altText:
      'The water treatment facility on the outskirts of Magic Johnson Park is a small, rectangular building near a patch of park lawn.',
    illoAltText:
      'Raindrops fall from the clouds onto the water treatment facility and the park nearby. The stormwater runoff is pumped through pipes into the facility, treated, and then pumped out into the lake. A family walks past maintenance workers inspecting the facility, while mallard ducks splash in rain-pools.',
    hearFromHilton: 'Hear from Hilton',
    hiltonPalm:
      'Willowbrook resident Hilton shares how Magic Johnson Park has benefitted his family.',
  },
  wetlands: {
    title: 'Wetlands',
    afterLeaving:
      'After leaving the treatment facility, the water flows into new wetlands planted along the edges of the southern lake. The water nourishes these natural features, and they help clean the water even further.',
    biofiltration:
      'Wetland plants like sedges and willows remove contaminants through a living process called biofiltration.',
    whatAreWetlands: 'What are wetlands?',
    filterChemicals:
      'Wetland plants help filter chemicals and pollutants in the lake water.',
    filterChemicalsAlt:
      'A close-up view of the wetland grasses in the lake at Magic Johnson Park.',
    howDoesBiofiltrationWork: 'How does biofiltration work?',
    whatMicrobes: 'What microbes are hiding in this lake?',
    whatMicrobesAlt:
      'A microscopic view of the varied and vibrant microbes residing in the lake at Magic Johnson Park.',
    whatBirdsLive: 'What birds live at Magic Johnson Park?',
    whatBirdsLiveAlt: 'An elegant egret surveys the lake from the shore.',
    wingedFriends:
      'Learn what species of winged friends live here at Magic Johnson Park.',
    wheresThisWater: 'Where’s this water coming from?',
    cleanWater:
      'Clean water flows into the lake for further wetland filtration.',
    whyRockWall: 'Why is there a rock wall?',
    slowIncomingWater:
      'The rock wall helps to slow incoming water, so that wetland plants can keep their footing.',
    slowIncomingWaterAlt:
      'A close-up view of the Gabion Wall, a wired cage of rocks that is partially submerged near the lakeshore.',
    altText:
      'A wide view of the southern lake. Ducks paddle around the lake and wetland plants. The water treatment facility is barely visible in the background.',
    illoAltText:
      'An egret and turtle rest upon the rock wall submerged in the lake. A Belted Kingfisher perches on a protruding branch from the water. Mallard ducks paddle past the wetland plants swaying in the wind. In the background, the water treatment facility continues to operate alongside the flora and fauna.',
  },
  lakes: {
    title: 'Lakes',
    afterTheWater:
      'After the water is cleaned by the wetlands, it flows into two 15-acre lakes.',
    holdStormwater:
      'The lakes’ ability to hold stormwater helps keep the neighborhood safe from flooding.',
    reduceWaterUse:
      'They help to reduce the park’s water use, too. Before the renovation, drinkable water was used to irrigate the park. Now, that irrigation is supplemented by recycled stormwater from the lake.',
    howWaterUsed: 'How is the water used?',
    graywater:
      'Before the renovation, the park used clean drinking water to irrigate the landscape - a precious resource that could be used more wisely. Now, the plants and trees at the park are watered with recycled stormwater (also called “graywater”), conserving potable water and saving money. Many LA residents can recycle water in their own homes by collecting detergent-free household water or rainwater and recycling it in their gardens.',
    beforeTheRenovation:
      'Before the renovation, the park’s irrigation used 100% potable water.',
    oakTrees: 'Learn about the magic of Oak trees',
    muchToTeach:
      'The oak trees of Magic Johnson Park have much to teach us about being water wise.',
    muchToTeachAlt:
      'A clear sky peeks through the curving branches and sunlit leaves of an oak tree.',
    whatIsThePathway: 'What is the pathway made of?',
    permeablePavingAlt: 'A close-up view of a sandy, permeable path.',
    permeablePaving: 'How do permeable pathways help keep the park cool?',
    peacefulSpot: 'Explore this peaceful spot',
    takeABreak: 'Take a break and enjoy the scenery.',
    takeABreakAlt:
      'White, tufted clouds hang over the blue sky at Magic Johnson Park.',
    whatCanLakesHold: 'What can the lakes hold?',
    lakesCanHold:
      'The lakes at the park can hold a staggering 4.5 million gallons of water!',
    lakeCapacity:
      'The two lakes at Magic Johnson Park cover a total of 15 acres and can hold a staggering 4.5 million gallons of water! This water washes in from first flush flows (a term used to describe initial surface runoff from a rainstorm). Between July 2021 to January 2022, the lakes collected 4 million gallons of water from the 375-acre community watershed. If the lakes weren’t there, this water would flow through the streets of Willowbrook, picking up pollutants and trash on its way to Compton Creek, where it would flow straight into the ocean. If the lakes reach capacity, the water can still overflow into Compton Creek, but without all the pollution that it would have contained had it not been treated at the park.',
    altText:
      'The perimeter of a lake in Magic Johnson Park. Wetland grasses and park greenery line the shore. The renovated community center lies in the distance.',
    illoAltText:
      'A cloud pours rain in the distance. Mallard ducks waddle past a dog walker. One child bikes down the permeated path, while another jumps in a puddle nearby. A landscaper uses the lake’s recycled stormwater to water native plants, and a couple enjoys the peaceful scene of birds flying over the lake.',
  },
  streamBridgeAndTrail: {
    title: 'Stream Bridge and Trail',
    gravityAndPumps:
      'Through gravity and pumps, the water flows between the lakes, treatment facility, and wetlands in a continuous loop. This movement helps to prevent algae from blooming, keeping the water healthy and clean.',
    soDoPeople: 'As the water moves around the lakes, so do people.',
    bridgeConnects:
      'A bridge connects a 1/2-mile trail that encircles the lakes. The bridge and trail both provide easy waterside access for walkers, joggers, families, and wildlife. And distance markers on the trail invite residents to stay healthy through movement, just like the water.',
    whyStreamHere: 'Why is there a stream here?',
    streamHereBecause: 'This stream helps to keep the park’s water healthy.',
    streamHereBecauseAlt: 'A close-up view of rushing water in a stream.',
    whatPlantsLive: 'What kind of plants live here?',
    californiaNatives:
      'California natives make up 80% of the new plants that were transplanted in the park.',
    californiaNativesAlt:
      'A close-up view of the radiating leaves of a California native sagebrush plant.',
    exploreTheBridge: 'Explore the bridge',
    stoppingPoint:
      'The bridge is a stopping point for many visitors at Magic Johnson Park. They can delight in the stream...',
    stoppingPointAlt:
      'The sun sets in the background of the stream bridge. Park-goers admire the stream below.',
    treesInThePark: 'What trees are in the park?',
    sourceOfShelter:
      'The trees of Magic Johnson Park are a source of shelter, sustenance, and play for many creatures.',
    sourceOfShelterAlt:
      'The leaves of a maple tree are illuminated by the setting sun.',
    altText:
      'A stream runs over a tiered path of boulders, and passes through a green landscape. A constructed bridge crosses over the stream.',
    illoAltText:
      'While recycled water rushes down the stream, bicyclists zoom past a bridge overhead. A child climbs an arching tree near the stream, and two runners stretch underneath the shade of its canopy. Native flowers bloom as pollinators zip by.',
    hearFromErika: 'Hear from Erika',
    erikaYanez:
      'Willowbrook resident Erika shares how she utilizes Magic Johnson Park for exercise and recreation.',
  },
  splashPad: {
    title: 'Splash Pad',
    socialTies:
      'Living infrastructure isn’t just about nurturing healthy ecosystems - it’s about social ties, too.',
    parkHighlight:
      'A highlight of the park is this playground splash pad, which uses recirculated water that’s safe to play in.',
    refreshingPlace:
      'The splash pad is a refreshing place for families to cool off and come together — celebrating the next generation and strengthening the vitality and resilience of the neighborhood as it heals.',
    whatAreStructures: 'What are these structures?',
    shadeStructures:
      'Shade structures help to reduce the urban heat island effect.',
    shadeSails:
      'These structures are called shade sails. They offer shelter from the heat of the sun, UV rays, and rain. They help to make the playground at Magic Johnson Park safer and more comfortable for visitors.',
    learnSplashPad: 'Learn about the splash pad',
    recirculateWater: 'How does the splash pad recirculate water?',
    coolRespite:
      'When activated, the splash pad sprays water that is clean enough to drink, delighting users and passersby. The water is a cool respite on hot summer days, and brings joy to families who visit the park. After soaking back into the pad, the used water drains into a wet well underground, where it receives a thorough filtration and treatment. It’s then sent back up through the splash pad again, returning to playful users. This recirculation process helps to conserve water, while refreshing the community.',
    takeBreak: 'Take a break',
    takeBreather: 'Take a breather on one of the park’s benches.',
    takeBreatherAlt:
      'A quiet scene of a bird floating on the lake near the community center. The rippling lake reflects the clouds and blue sky above.',
    altText:
      'A circular splash pad with embedded sprinklers sits next to a play area. Triangular sails hang over half the play area to provide shade.',
    illoAltText:
      'Kids play on a variety of structures including a slide, a climbing structure, and a splash pad. A parent pushes their stroller past the splash pad, which sprinkles water over delighted kids. Birds fly above the trees and the constructed triangular sails - both of which provide shade to the play area.',
  },
  communityCenter: {
    title: 'Community Center',
    newGatheringSpace:
      'Overlooking the lakes is a 20,000-square-foot community center. This new gathering space helps to knit the community together.',
    sweepingMural:
      'A sweeping mural spans the ceiling in the center. It was created by Los Angeles-based artist Carla Jay Harris, who said of the piece, “The central concept is a set of guardians put in place to oversee and protect a community sanctuary.”',
    renewSolidarity:
      'As remediation of the site continues, the community center offers a place for neighbors to renew their sense of solidarity together.',
    whatsThisArtwork: 'What’s this artwork?',
    carlaJayHarris:
      'The mural that encircles the community center was created by Carla Jay Harris.',
    carlaJayHarrisAlt:
      'Carla Jay Harris, the painter of the mural inside the Community Center.',
    centerProgramming: 'Community Center programming',
    ampleEvents: 'The center has ample events and programming.',
    rosterOfEvents:
      'The community center offers a full and dynamic roster of events and programs. There are countless ways for residents to engage with each other, learn, play, relax, and grow at the center. From after-school snacks and homework help, to concerts, senior days, gardening, financial courses, and a mobile medicine clinic, the park has ample resources for a wide variety of audiences. <a href="https://parks.lacounty.gov/earvin-magic-johnson-recreation-area/#" target="_blank">Click here</a> to view the full list of programming.',
    hearFromWilly: 'Hear from Willy',
    willyWilliams:
      'Willowbrook resident Willy shares how Magic Johnson Park has benefitted the community.',
    hearFromJohn: 'Hear from John',
    johnDavis:
      'Willowbrook resident John shares how Magic Johnson Park has benefitted the community.',
    altText:
      'An intricate mural by Carla Jay Harris encircles the inside of the center. There are tables and a stage, which has ramp access for disabled people.',
    illoAltText:
      'A variety of activities are going on alongside a yoga class being conducted onstage. At the round tables below, residents and children are playing games, doing homework together, eating food, surfing the web, playing guitar, among other activities.',
  },
  conclusion: {
    cleanupEfforts:
      'Cleanup efforts are ongoing at Magic Johnson Park; an underground chemical vapor plume persists in the southeast region of the site, and certain species of fish are still too poisonous to eat.',
    healthierPlace:
      'But better water quality, increased flood protection, and safer places to gather have made Willowbrook a healthier place to call home.',
    bringingItAlive:
      'And connecting the park’s built features, natural habitats, and social fabric — its living infrastructure — has been key to bringing it alive.',
    tendingInfrastructure:
      'Tending to this living infrastructure will help the changes take root and thrive, as efforts to improve the park continue.',
    altText:
      'A wide view of one of the lakes, the community center, the wetland plants, and four picnic benches.',
    illoAltText:
      'Flowers and grasses wind along the permeable paths. Trees, sagebrush, and other plants encircle the lake. A birdwatcher admires an egret, and a visitor admires the biodiverse lake. The wastewater treatment facility feeds treated stormwater back into the lake as the community center’s mural shines above.',
  },
  projectSummary: {
    title: 'Want to reimagine your own space?',
    description:
      'Learn how the Field Kit can help you and your community imagine possibilities for climate resilience in your neighborhood.',
    exploreUses: 'Explore Uses for the Field Kit',
    requestAccess: 'Request Access to the Visioning Tool',
    altText:
      'A wide, paved street lined with cars, power lines, and minimal greenery crosses through the houses of downtown Los Angeles. Smog partially obscures the distant buildings in the downtown area.',
    exploreUsesAltText:
      'A walking path winds through a lush, green park dotted with trees, as well as a drinking fountain for visitors.',
    requestAccessAltText:
      'A screenshot of the Visioning Tool displays various remedy icons attached to different neighborhoods on a map of Los Angeles, resembling stickers.',
  },
  projectCredits: {
    altText:
      'An overhead shot of ducks swimming in the blue waters of one of the lakes at Magic Johnson Park.',
    livingInfraAlt:
      'Linking arms, a stormwater pipe, and a tree branch intertwine for mutual support.',
    landWaterAcknowledgement: 'Land and Water Acknowledgment',
    statement: [
      'The creators of the Living Infrastructure Field Kit acknowledge that the geographic area represented in these tools (the Greater LA County Integrated Regional Water Management Area) is the unceded ancestral lands and waters of the Tongva, Chumash, and Tataviam people, the original stewards of these lands and waters.',
      'We honor their elders both past and present and the descendants who are citizens of these tribes for their exemplary respect for water; we honor their continued connection to and protection of one of the most beautiful and diverse landscapes in the world.',
      'May this be in service of helping to restore and strengthen relationships to and awareness of cultural histories and ecological contexts.',
      'To learn more, please visit the <a href="http://www.sacredplacesinstitute.org" target="_blank">Sacred Places Institute for Indigenous Peoples</a> and the <a href="https://tongva.land" target="_blank">Tongva Conservancy</a>.',
    ],
    creditsTitle: 'Credits',
    credits: [
      '“After the Rain” was created with care and community by ARLA and Spherical.',
      'This project was made possible through a deeply collaborative process, and we would like to thank the people below for sharing their time and talents:',
    ],
    feedbackTitle: 'Feedback and Advising',
    feedbackCredits: [
      'Los Angeles County Department of Public Works',
      'Council for Watershed Health',
      'TreePeople',
      'Promesa Boyle Heights',
      'Pacoima Beautiful',
      'Willowbrook Inclusion Network',
      'Wendy Chan',
      'Barry Lehrman',
      'Mike Antos, Stantec',
      'Rosey Jencks',
    ],
    artMediaTitle: 'Art and Media',
    artMediaCredits: [
      'Photo and video by Rigo Santoyo, Junior Real, Kwasi Boyd-Bouldin, Chloé Savard, Irina Logra, and Erik Shute',
      'Illustration by Wyatt Hersey',
      'Animation by Claud Li',
      'Voiceover by Anthony Belcher, Sophia Guillen, Laneshia Miller, Alberto Pena, and Jonathan Pratt',
    ],
    additionalCredits: [
      'The Magic Johnson Park Renovation is a project by Los Angeles County, Paul Murdoch Architects, AHBE | MIG landscape architecture and urban planning and design, and Pacific Advanced Civil Engineering (PACE).',
      'Accelerate Resilience L.A. (ARLA) is a sponsored project of Rockefeller Philanthropy Advisors.',
    ],
    referencesTitle: 'COMPILED REFERENCES (MLA, 9th Edition)',
    references: [
      "Barragan, Bianca. <a href='https://la.curbed.com/2019/3/8/18256280/magic-johnson-park-willowbrook-renovations-begin' target='_blank' rel='noreferrer'>“$70M Renovation on Willowbrook’s Magic Johnson Park Getting Underway.”</a> Curbed Los Angeles, 8 Mar. 2019.",
      "California Water Boards, and CalEPA. <a href='https://www.waterboards.ca.gov/rwqcb4/water_issues/programs/remediation/Former_Athens_Tank_Farm/Fact%20Sheet%203_%20FINAL%20pdf.PDF' target='_blank' rel='noreferrer'>FACT SHEET #3: Former Athens Tank Farm</a>. California Water Boards &amp; CalEPA, May 2012.",
      "Dale, Mariana. <a href='https://laist.com/news/climate-environment/los-angeles-stormwater-rainwater-capture-treatment-reuse-willowbrook-earvin-magic-johnson-park' target='_blank' rel='noreferrer'>“Willowbrook Park, Fed By Rainwater, Is An Example of LA’s Stormwater Treatment Future.”</a> LAist, 10 Jan. 2023.",
      "<a href='https://www.youtube.com/watch?v=Th1oGGYkQ9s' target='_blank' rel='noreferrer'>Earvin “Magic” Johnson Park Urban Runoff Water Recycling</a>. Directed by PACE water, 2022.",
      'Hennessy-Fiske, Molly. “A Community Is Sundered: Tainted Soil Leads to Closure of What Was Once a Tight-Knit Complex.” Los Angeles Times, 17 Apr. 2009.',
      'Hennessy-Fiske, Molly. “County OKs Plan to Move Tenants.” Los Angeles Times, 15 Apr. 2009.',
      'Hennessy-Fiske, Molly. “Hundreds Sure L.A. County over Contamination.” Los Angeles Times, 13 Apr. 2010.',
      'Jones, Jack. “Opening of Ujima Village Set.” Los Angeles Times, 2 Nov. 1972.',
      'Jones, Jack. “Ujima Village to Open Saturday.” Los Angeles Times, 3 Nov. 1972.',
      'Kleinfelder West, Inc. Cultural Resource Evaluation of the Former Athens Tank Farm. addendum to the California Environmental Quality Act (CEQA) Guidelines Appendix G: Environmental Checklist Form, Section V Cultural Resources., ExxonMobil Environmental Services Company, 6 Aug. 2012.',
      'Knox, Charles E. “Community Support Spurs Black Project.” Los Angeles Times, 28 Feb. 1971.',
      'Kumbula, Tendayi J. “Ujima Village, Black Housing Project, Opens.” Los Angeles Times, 5 Nov. 1972.',
      "Miranda, Carolina A. “<a href='https://enewspaper.latimes.com/infinity/article_share.aspx?guid=38468632-4b88-43fe-aeb9-232ccaed50e5' target='_blank' rel='noreferrer'>It’s Not All Fun and Games: While Visitors Relax, Magic Johnson Park Does Crucial Work, Capturing and Recycling Water</a>.” Los Angeles Times, 23 May 2021.",
      "Miranda, Carolina A. <a href='https://www.latimes.com/entertainment-arts/story/2021-05-20/magic-johnson-park-water-capture-station' target='_blank' rel='noreferrer'>“What’s Hiding in Plain Sight at Magic Johnson Park? Maybe a Solution to Our Water Problem.”</a> Los Angeles Times, 20 May 2021.",
      'Morain, Dan, and Jill Stewart. “Foreclosure on 2 Privately Run Projects Sought.” Los Angeles Times, 26 Sept. 1989.',
      "Powell, Amy. <a href='https://abc7.com/lakers-magic-johnson-south-la-park/6794252/' target='_blank' rel='noreferrer'>“Sneak Peek at Magic Johnson Park after $70 Million Renovations Completed.”</a>ABC News, 5 Oct. 2020.",
      'Ridley-Thomas, Mark. Earvin “Magic” Johnson Recreation Area Revised Master Plan. County of Los Angeles Department of Parks and Recreation, Sept. 2019.',
      "Ridley-Thomas, Mark. <a href='https://ridley-thomas.lacounty.gov/index.php/magic-johnson-park/' target='_blank' rel='noreferrer'>“More Magic on Its Way to Magic Johnson Park.”</a> The Legacy of Mark Ridley-Thomas, Second District Supervisor 2008-2020.",
      'Rogan, Sean, and Los Angeles Housing Authority. Approve a Partial Settlement Agreement with HUD for Use of Ujima Village Replacement Reserves and Award a Contract to National Demolition Contractors for Demolition of Ujima Village. 19 June 2012.',
      'Second Supervisorial Districit. “New Project: Housing Authority of the County of Los Angeles Housing Management Division. Ujima Village Demolition Allocation: &lt;$1,000,000&gt;.” Los Angeles Times, 2011.',
      "Sentinel News Service. <a href='https://lasentinel.net/supervisor-ridley-thomas-and-partners-host-sneak-peek-of-70m-magic-johnson-park-renovation-and-community-drive-thru-tour.html' target='_blank' rel='noreferrer'>“Supervisor Ridley-Thomas and Partners Host Sneak Peek of $70m Magic Johnson Park Renovation and Community Drive-Thru Tour.”</a> Los Angeles Sentinel, 8 Oct. 2020.",
      "Sprowls, Robert, and Dave Schickling. <a href='http://www.gswater.com/WillowbrookCCR' target='_blank' rel='noreferrer'>Willowbrook Water System</a>. Golden State Water Company, 2021.",
      'State Water Resources Control Board. GeoTracker: HAZARDOUS MATERIAL INCIDENT REPORT FILED WITH OES. State Water Resources Control Board, 17 Nov. 2007.',
      "<a href='https://www.youtube.com/watch?v=XA6Eeh8aw9A.' target='_blank' rel='noreferrer'>The Live Oak | Julia Bogany</a>, Tongva Elder. Directed by Bridge Projects, 2020.",
      'Turpin, Dick. “Major Black Complex to Be Launched.” Los Angeles Times, 21 Feb. 1971.',
      'Unger, Sam. Former Athens Tank Farm Environmental Investigation &amp; Cleanup. Los Angeles Regional Water Quality Control Board Community Meeting.',
      'Unknown. “County Park Named for Magic Johnson.” Los Angeles Times, 20 Nov. 1991.',
    ],
  },
  settings: {
    title: 'Settings',
    highContrast: 'High Contrast',
    language: 'Language',
    languages: [
      { label: 'English', value: 'english' },
      { label: 'Spanish', value: 'spanish' },
    ],
    fontSize:
      'To adjust the readability of this interactive, use your browser or computer’s sizing controls to increase or decrease the font size.',
    motionReduced:
      'This interactive is respecting your device’s current global motion preferences by minimizing motion and animation.',
    motionNotReduced:
      'This interactive respects your device’s current global motion preferences; however, you can adjust your system settings for further accessibility.',
  },
  transcripts: {
    johnDavis: {
      title: 'Interview with John Davis',
      text: '<p>0:01<br>My name is John Davis. I’m very delighted that the park sits right in the middle of the community - that people in the community that live in the community can go to - they don’t have to travel to the other parks, they’re able to go to the park, do walks, have activities.</p><p>0:15<br>Matter of fact, I, I like the park. We did my mom’s 75th birthday at the recreation center at the park.</p><p>0:23<br>So when you go to the park, it’s just a different atmosphere; you see more people coming together. It feels good and you see the smiles on their faces and the energy because they have something of their own. It just the fact that you got a park in your community.</p><p>0:38<br>If the larger community comes out from Willowbrook (comes out and sees Magic Johnson Park), you’ll be amazed of all the other things that go on at the park - the walks, the features, the things that kids play. You’d just be amazed.</p>',
    },
    willyWilliams: {
      title: 'Interview with Willy Williams',
      text: '<p>0:00<br>What that part does for my community is, it brings beauty to where tragedy once was.</p><p>0:09<br>I’m 65 now, I’ve been over here for 64 years. I was raised over here. I was raised in Willowbrook. I was here when this whole thing wasn’t nothing but old buildings and stuff like that.</p><p>0:22<br>But as far as I’m concerned, that’s the best thing they could have did to Willowbrook, was to build Magic Johnson park.</p><p>0:29<br>Willowbrook is my home. Willowbrook is my home.</p>',
    },
    hiltonPalm: {
      title: 'Interview with Hilton Palm',
      text: '<p>0:00<br>My name is Hilton. Hilton Palm.</p><p>0:02<br>I remember when this wasn’t a park, before it was a park. I’ve been here a long time. With all the new renovations, the dog park, the track over there, and all the other exercise equipment, I come to the park more. I love it.</p><p>0:15<br>Besides bringing my son to the playground, we go feed the ducks, we take the dog (I have a dog), we take the dog to the dog park, and just walk around. You know, I haven’t fished but I’m thinking about getting some fishing poles and put my poles in the water.</p><p>0:33<br>I love it. it’s affected me because I come to the park more.</p>',
    },
    erikaYanez: {
      title: 'Interview with Erika Yanez',
      text: '<p>0:01<br>I grew up here in this area. So as a child, my parents introduced us to the park and we would just come and feed the birds and just be out in that little lake area out there.</p><p>0:13<br>And then as an adult, I used to bring my kids for Easter and family gatherings, but now I use it more as a form of workout and I like the markings on the ground - it tells you how much you walked or how much distance, so that’s helpful for me just to know how much I’ve accomplished.</p><p>0:33<br>It seems like it’s cleaner now, more than it used to be in the past. The restrooms for the most part are always well kept and then we have two restrooms open.</p><p>0:45<br>I find it a safe park. I haven’t encountered any challenges or any aggressions.</p>',
    },
  },
}
