import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useLanguageStore = defineStore({
  id: 'language',
  state: () => ({
    language: useStorage('language', 'english'),
    languages: [
      {
        name: 'english',
        label: {
          english: 'English',
          spanish: 'Inglés',
        },
      },
      {
        name: 'spanish',
        label: {
          english: 'Spanish',
          spanish: 'Español',
        },
      },
    ],
  }),
  getters: {
    isValidLanguage: (state) => (language) => {
      return (
        language &&
        !!state.languages.find((l) => l.name === language.toLowerCase())
      )
    },
  },
  actions: {
    setLanguage(value: string | null) {
      if (this.isValidLanguage(value)) {
        this.language = value?.toLowerCase() || 'english'
        // save to local storage
        localStorage.setItem('language', this.language)
      }
    },
    toggleLanguage() {
      if (this.language === 'english') {
        this.language = 'spanish'
        document.documentElement.lang = 'es'
      } else if (this.language === 'spanish') {
        this.language = 'english'
        document.documentElement.lang = 'en'
      }
    },
  },
})
