<template>
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75874 5.57351L1.52098 0.670259C0.865385 0.214142 0 0.727273 0 1.58249V11.4175C0 12.2727 0.865385 12.7859 1.52098 12.3297L8.75874 7.42649C9.38811 6.97037 9.38811 6.00112 8.75874 5.57351Z"
      fill="#3A3D40"
    />
  </svg>
</template>
