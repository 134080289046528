import {
  MJPSection,
  SectionSequence,
} from '@/components/magicjohnsonpark/types'
import useSectionIndex from './useSectionIndex'

export default function useNextSection(section) {
  const index = useSectionIndex(section)
  if (index == SectionSequence.length - 1) {
    return MJPSection.None
  } else if (index >= 0) {
    return SectionSequence[index + 1].section
  } else {
    return MJPSection.None
  }
}
