import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

export const useSettingsStore = defineStore({
  id: 'settings',
  state: () => {
    return {
      highContrast: useLocalStorage('highContrast', false),
      largerText: useLocalStorage('largerText', false),
    }
  },
  getters: {},
  actions: {
    setHighContrast(value: boolean) {
      this.highContrast = value
    },
    setLargerText(value: boolean) {
      this.largerText = value
    },
    toggleHighContrast() {
      this.highContrast = !this.highContrast
    },
    toggleLargerText() {
      this.largerText = !this.largerText
    },
  },
})
