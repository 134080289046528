import { ScrollOffset } from '@/components/general/types'
import useVisibleHotspotCount from '@/composables/useVisibleHotspotCount'
import { gsap } from 'gsap'

export default function useScrollOffset(
  position,
  backgroundState,
  hotspotData,
  hotspotRefsById,
  scrollProgress,
  windowSize
) {
  const offset: ScrollOffset = {
    proxyPosition: '',
    offsetX: 0,
  }
  const ease = gsap.parseEase('power1.inOut')

  if (!hotspotData) hotspotData = []
  if (!hotspotRefsById) hotspotRefsById = {}

  if (
    useVisibleHotspotCount(hotspotData, hotspotRefsById) < hotspotData.length
  ) {
    const positions = position.split(' ')
    const scrollProgressProxy = ease(Math.max(0, scrollProgress))
    offset.proxyPosition = scrollProgressProxy * 100 + '% ' + positions[1]
    offset.offsetX =
      scrollProgressProxy *
      (backgroundState.naturalWidth * backgroundState.scaleRatio - windowSize.x)
    const positionRatio = parseFloat(positions[0]) * 0.01
    offset.offsetX -=
      (backgroundState.naturalWidth * backgroundState.scaleRatio -
        windowSize.x) *
      positionRatio
  } else {
    offset.proxyPosition = position
    offset.offsetX = 0
  }
  return offset
}
