<template>
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.56066 8.56066C8.27936 8.84197 7.89783 9 7.5 9C7.10217 9 6.72064 8.84197 6.43934 8.56066L0.43934 2.56066C-0.146447 1.97488 -0.146447 1.02513 0.43934 0.439342C1.02513 -0.146443 1.97487 -0.146443 2.56066 0.439342L6 3.87868L7.5975 5.3625L9 3.87868L12.4393 0.439341C13.0251 -0.146445 13.9749 -0.146445 14.5607 0.439341C15.1464 1.02513 15.1464 1.97487 14.5607 2.56066L8.56066 8.56066Z"
      fill="white"
    />
  </svg>
</template>
