/**
 * Contains hotspot data for each section of the MJP tour. Tour sections are
 * indexed by id, and each contains an array of hotspots. For details on the
 * data format for each hotspot, see the HotSpotData interface.
 */
export default {
  waterTreatmentFacility: [
    {
      id: 'whatsGoingOn',
      left: 999,
      top: 538,
      animation: {
        id: 'waterstation',
        layout: {
          left: '0',
          bottom: '111px',
          width: '1650',
        },
        mute: 'waterstation2',
      },
      popover: {
        remedyCategory: 'info',
        direction: 'top',
        alignment: 'center',
        text: 'waterTreated',
        action: {
          type: 'learnMore',
          text: 'learnMore',
          content: 'pumpingStation',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'bottom',
        alignment: 'center',
        text: 'whatsGoingOn',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'watershed',
      left: 212,
      top: 749,
      animation: {
        id: 'waterstation2',
        fileId: 'waterstation',
        layout: {
          left: '0',
          bottom: '111px',
          width: '1650',
        },
        mute: 'waterstation',
      },
      popover: {
        remedyCategory: 'info',
        direction: 'right',
        alignment: 'top',
        text: 'howDoesParkRedirect',
        action: {
          type: 'learnMore',
          text: 'learnMore',
          content: 'willowbrookWaterSource',
          destination: 'watershedContext',
          destinationText: 'backToWatershed',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'top',
        alignment: 'center',
        text: 'waterSource',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'hiltonPalm',
      left: 1570,
      top: 770,
      animation: {
        id: 'umbrella',
        layout: {
          right: '41px',
          bottom: '83px',
          width: '400',
        },
      },
      popover: {
        remedyCategory: 'audio',
        direction: 'left',
        alignment: 'top',
        text: 'hiltonPalm',
        transcript: 'hiltonPalm',
        action: {
          type: 'playAudio',
          content: 'interview-hilton-palm-norm.mp3',
        },
      },
      preview: {
        remedyCategory: 'audio',
        direction: 'bottom',
        alignment: 'center',
        text: 'hearFromHilton',
        action: {
          type: 'preview',
        },
      },
    },
  ],
  wetlands: [
    {
      id: 'whatAreWetlands',
      left: 1480,
      top: 446,
      animation: {
        id: 'wetlandsOverview',
        layout: {
          right: '0',
          top: '70px',
          width: '600',
        },
        mute: 'filtration',
      },
      popover: {
        remedyCategory: 'video',
        direction: 'left',
        alignment: 'top',
        text: 'filterChemicals',
        thumbnail: 'filterChemicals-thumbnail.jpg',
        thumbnailAlt: 'filterChemicalsAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_2_Wetlandoverview.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: 'e839eeb205fcd964671d43c19a6a8336',
            ES: '6cce4dbc217c2a68e96b7c984a47a95b',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'whatAreWetlands',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'howDoesBiofiltrationWork',
      left: 1671,
      top: 767,
      animation: {
        id: 'filtration',
        layout: {
          right: '0',
          top: '113px',
          width: '600',
        },
        mute: 'wetlandsOverview',
      },
      popover: {
        remedyCategory: 'video',
        direction: 'left',
        alignment: 'top',
        text: 'whatMicrobes',
        thumbnail: 'whatMicrobes-thumbnail.jpg',
        thumbnailAlt: 'whatMicrobesAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_2_Biofiltration.mp4',
          width: 1080,
          height: 1920,
          videoUID: {
            EN: '0572b5c0d84ece721ca6b7934da6c03c',
            ES: '4bf69f32b699c4ebe68d296170d4b5ab',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'howDoesBiofiltrationWork',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'whatBirdsLive',
      left: 579,
      top: 795,
      animation: {
        id: 'birds',
        layout: {
          left: '505px',
          top: '475px',
          width: '300',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'left',
        alignment: 'top',
        text: 'wingedFriends',
        thumbnail: 'wingedFriends-thumbnail.jpg',
        thumbnailAlt: 'whatBirdsLiveAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_2_BirdsofMJP.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: '992ef4f76a7aa7c5c4366d7e9d1ef17d',
            ES: 'd750fe3b1f79bae24d8a86446f81ce10',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'whatBirdsLive',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'wheresThisWater',
      left: 607,
      top: 402,
      animation: {
        id: 'stormwater',
        layout: {
          left: '455px',
          top: '463px',
          width: '300',
        },
      },
      popover: {
        remedyCategory: 'info',
        direction: 'top',
        alignment: 'center',
        text: 'cleanWater',
        action: {
          type: 'goToSection',
          text: 'backToWaterTreatmentFacility',
          destination: 'waterTreatmentFacility',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'top',
        alignment: 'center',
        text: 'wheresThisWater',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'whyRockWall',
      left: 974,
      top: 841,
      animation: {
        id: 'gabion',
        layout: {
          left: '910px',
          top: '630px',
          width: '500',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'top',
        alignment: 'center',
        text: 'slowIncomingWater',
        thumbnail: 'slowIncomingWater-thumbnail.jpg',
        thumbnailAlt: 'slowIncomingWaterAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_2_Gabionwall.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: '15daacb718f5bb515cd08f39d3740ff9',
            ES: 'b2057b66ba210d278a515c676eff0cc6',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'top',
        alignment: 'center',
        text: 'whyRockWall',
        action: {
          type: 'preview',
        },
      },
    },
  ],
  lakes: [
    {
      id: 'howWaterUsed',
      left: 1388,
      top: 842,
      animation: {
        id: 'irrigation',
        layout: {
          right: '-22px',
          bottom: '-42px',
          width: '950',
        },
      },
      popover: {
        remedyCategory: 'info',
        direction: 'left',
        alignment: 'top',
        text: 'beforeTheRenovation',
        action: {
          type: 'learnMore',
          text: 'learnMore',
          content: 'graywater',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'top',
        alignment: 'center',
        text: 'howWaterUsed',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'oakTrees',
      left: 1697,
      top: 487,
      animation: {
        id: 'oakTrees',
        layout: {
          right: '0',
          bottom: '121px',
          width: '350',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'left',
        alignment: 'top',
        text: 'muchToTeach',
        thumbnail: 'oakTrees-thumbnail.jpg',
        thumbnailAlt: 'muchToTeachAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_3_Oaks.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: 'cd5de16326b757be5681bd2e490656dd',
            ES: 'b17c36020ff0d2d7553bd4a5361bc860',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'oakTrees',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'whatIsThePathway',
      left: 365,
      top: 852,
      animation: {
        id: 'pathway',
        layout: {
          left: '2px',
          bottom: '0',
          width: '1200',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'right',
        alignment: 'top',
        text: 'permeablePaving',
        thumbnail: 'permeablePathway-thumbnail.jpg',
        thumbnailAlt: 'permeablePavingAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_3_Permeablepathways.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: '8a1efae8c77d43a5229e023e93addff3',
            ES: 'a7d9e388461b26a5aec2340869c95bdf',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'top',
        alignment: 'center',
        text: 'whatIsThePathway',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'peacefulSpot',
      left: 300,
      top: 624,
      animation: {
        id: 'takeABreak',
        layout: {
          left: '0',
          top: '458px',
          width: '300',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'right',
        alignment: 'top',
        text: 'takeABreak',
        thumbnail: 'takeALakeBreak-thumbnail.jpg',
        thumbnailAlt: 'takeABreakAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_3_Takeabreak.mp4',
          width: 3840,
          height: 2048,
          videoUID: {
            EN: 'fac6429d4c8ed5d5b44950f9aecbb3b1',
            ES: 'fac6429d4c8ed5d5b44950f9aecbb3b1',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'peacefulSpot',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'whatCanLakesHold',
      left: 990,
      top: 614,
      animation: {
        id: 'lake',
        layout: {
          right: '66px',
          bottom: '175px',
          width: '1600',
        },
      },
      popover: {
        remedyCategory: 'info',
        direction: 'right',
        alignment: 'top',
        text: 'lakesCanHold',
        action: {
          type: 'learnMore',
          text: 'learnMore',
          content: 'lakeCapacity',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'bottom',
        alignment: 'center',
        text: 'whatCanLakesHold',
        action: {
          type: 'preview',
        },
      },
    },
  ],
  streamBridgeAndTrail: [
    {
      id: 'whyStreamHere',
      left: 342,
      top: 701,
      animation: {
        id: 'stream',
        layout: {
          left: '0',
          bottom: '79px',
          width: '1050',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'right',
        alignment: 'center',
        text: 'streamHereBecause',
        thumbnail: 'whyStreamHere-thumbnail.jpg',
        thumbnailAlt: 'streamHereBecauseAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_4_Stream.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: '49e3200f5931e415081d86112c741d1f',
            ES: '6780d781f5038598e7223bf7c5723d21',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'whyStreamHere',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'whatPlantsLive',
      left: 737,
      top: 827,
      animation: {
        id: 'nativePlants',
        layout: {
          left: '166px',
          bottom: '0',
          width: '550',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'right',
        alignment: 'top',
        text: 'californiaNatives',
        thumbnail: 'californiaNatives-thumbnail.jpg',
        thumbnailAlt: 'californiaNativesAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_4_Nativeplants.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: 'cc76c5e1e8cae8a06f0149adc8b71b9a',
            ES: 'c4fcc9c4207a882bd127a7e3f055d0d7',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'top',
        alignment: 'center',
        text: 'whatPlantsLive',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'exploreTheBridge',
      left: 1380,
      top: 461,
      animation: {
        id: 'bridge',
        layout: {
          left: '0',
          bottom: '389px',
          width: '1920',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'left',
        alignment: 'bottom',
        text: 'stoppingPoint',
        thumbnail: 'stoppingPoint-thumbnail.jpg',
        thumbnailAlt: 'stoppingPointAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_4_Takeabreak.mp4',
          width: 3840,
          height: 2048,
          videoUID: {
            EN: '977c8c46f14b5ae8a7aca50d05f7b530',
            ES: '977c8c46f14b5ae8a7aca50d05f7b530',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'exploreTheBridge',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'treesInThePark',
      left: 247,
      top: 264,
      animation: {
        id: 'trees',
        layout: {
          left: '0',
          top: '40px',
          width: '400',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'right',
        alignment: 'center',
        text: 'sourceOfShelter',
        thumbnail: 'sourceOfShelter-thumbnail.jpg',
        thumbnailAlt: 'sourceOfShelterAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_4_TreesofMJP.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: 'eee8efc00393b96f6a74cf4779d0acd0',
            ES: 'ab4ea3dd0f57a6038e174c7d17a0847d',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'treesInThePark',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'erikaYanez',
      left: 1470,
      top: 870,
      animation: {
        id: 'bending',
        layout: {
          right: '408px',
          bottom: '146px',
          width: '326',
        },
      },
      popover: {
        remedyCategory: 'audio',
        direction: 'left',
        alignment: 'top',
        text: 'erikaYanez',
        transcript: 'erikaYanez',
        action: {
          type: 'playAudio',
          content: 'interview-erika-yanez-norm.mp3',
        },
      },
      preview: {
        remedyCategory: 'audio',
        direction: 'bottom',
        alignment: 'center',
        text: 'hearFromErika',
        action: {
          type: 'preview',
        },
      },
    },
  ],
  splashPad: [
    {
      id: 'whatAreStructures',
      left: 580,
      top: 441,
      animation: {
        id: 'shade',
        layout: {
          left: '510px',
          top: '210px',
          width: '700',
        },
      },
      popover: {
        remedyCategory: 'info',
        direction: 'right',
        alignment: 'top',
        text: 'shadeStructures',
        action: {
          type: 'learnMore',
          text: 'learnMore',
          content: 'shadeSails',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'bottom',
        alignment: 'center',
        text: 'whatAreStructures',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'learnSplashPad',
      left: 1283,
      top: 843,
      animation: {
        id: 'splashPad',
        layout: {
          left: '560px',
          bottom: '200px',
          width: '1000',
        },
      },
      popover: {
        remedyCategory: 'info',
        direction: 'left',
        alignment: 'top',
        text: 'recirculateWater',
        action: {
          type: 'learnMore',
          text: 'learnMore',
          content: 'coolRespite',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'bottom',
        alignment: 'center',
        text: 'learnSplashPad',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'takeBreak',
      left: 1713,
      top: 580,
      animation: {
        id: 'bench',
        layout: {
          left: '1610px',
          bottom: '370px',
          width: '200',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'left',
        alignment: 'top',
        text: 'takeBreather',
        thumbnail: 'takeABreather-thumbnail.jpg',
        thumbnailAlt: 'takeBreatherAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_5_Takeabreak.mp4',
          width: 3840,
          height: 2048,
          videoUID: {
            EN: '6614457f8d79eba4293ddfe059060025',
            ES: '6614457f8d79eba4293ddfe059060025',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'top',
        alignment: 'center',
        text: 'takeBreak',
        action: {
          type: 'preview',
        },
      },
    },
  ],
  communityCenter: [
    {
      id: 'whatsThisArtwork',
      left: 1050,
      top: 283,
      animation: {
        id: 'mural',
        layout: {
          left: '148px',
          top: '151px',
          width: '1200',
        },
      },
      popover: {
        remedyCategory: 'video',
        direction: 'left',
        alignment: 'center',
        text: 'carlaJayHarris',
        thumbnail: 'carlaJayHarris-thumbnail.jpg',
        thumbnailAlt: 'carlaJayHarrisAlt',
        action: {
          type: 'playVideo',
          content: 'Stop_6_Mural.mp4',
          width: 2024,
          height: 1080,
          videoUID: {
            EN: '7a5e4e6eec2249457b7f3d1b8765cc58',
            ES: 'cff29eed86f7de4a3514e55c8813acc9',
          },
        },
      },
      preview: {
        remedyCategory: 'video',
        direction: 'bottom',
        alignment: 'center',
        text: 'whatsThisArtwork',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'centerProgramming',
      left: 540,
      top: 809,
      animation: {
        id: 'cheer',
        layout: {
          left: '415px',
          bottom: '276px',
          width: '250',
        },
      },
      popover: {
        remedyCategory: 'info',
        direction: 'right',
        alignment: 'top',
        text: 'ampleEvents',
        action: {
          type: 'learnMore',
          text: 'learnMore',
          content: 'rosterOfEvents',
        },
      },
      preview: {
        remedyCategory: 'info',
        direction: 'bottom',
        alignment: 'center',
        text: 'centerProgramming',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'willyWilliams',
      left: 290,
      top: 700,
      animation: {
        id: 'directions',
        layout: {
          left: '90px',
          bottom: '-40px',
          width: '400',
        },
      },
      popover: {
        remedyCategory: 'audio',
        direction: 'right',
        alignment: 'top',
        text: 'willyWilliams',
        transcript: 'willyWilliams',
        action: {
          type: 'playAudio',
          content: 'interview-williy-williams.mp3',
        },
      },
      preview: {
        remedyCategory: 'audio',
        direction: 'bottom',
        alignment: 'center',
        text: 'hearFromWilly',
        action: {
          type: 'preview',
        },
      },
    },
    {
      id: 'johnDavis',
      left: 1550,
      top: 650,
      animation: {
        id: 'cooking',
        layout: {
          left: '1309px',
          bottom: '15px',
          width: '300',
        },
      },
      popover: {
        remedyCategory: 'audio',
        direction: 'left',
        alignment: 'top',
        text: 'johnDavis',
        transcript: 'johnDavis',
        action: {
          type: 'playAudio',
          content: 'interview-john-davis.mp3',
        },
      },
      preview: {
        remedyCategory: 'audio',
        direction: 'bottom',
        alignment: 'center',
        text: 'hearFromJohn',
        action: {
          type: 'preview',
        },
      },
    },
  ],
}
