import { SectionSequence } from '@/components/magicjohnsonpark/types'
import useSectionIndex from './useSectionIndex'

export default function useSectionData(section) {
  const index = useSectionIndex(section)
  if (index != -1) {
    return SectionSequence[index]
  } else {
    return SectionSequence[0]
  }
}
